import React from "react";
import rehypeReact from "rehype-react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { nl2br, HyperLink, StyledImg, Blockquote } from "~/molecules/BaseBody";

type Node = {
  children?: React.ReactNode;
};

export const Paragraph: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .paragraph {
          color: ${colors.defaultText};
          margin: 20px 0 0;
          font-size: 1.7rem;
          line-height: 2;
          font-weight: 400;
        }
        .paragraph:first-child {
          margin: 0;
        }
        .paragraph :global(strong) {
          font-weight: 700;
        }
      `}</style>
      <p className="paragraph">{node.children}</p>
    </>
  );
};

export const Heading1: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading1 {
          color: ${colors.defaultText};
          margin: 30px 0;
          font-weight: 500;
        }
        @media (${breakPoints.sp}) {
          .heading1 {
            font-size: 3.2rem;
          }
        }
        @media (${breakPoints.minPc}) {
          .heading1 {
            font-size: 3.6rem;
          }
        }
      `}</style>
      <h1 className="heading1">{node.children}</h1>
    </>
  );
};

export const Heading2: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading2 {
          color: ${colors.defaultText};
          margin: 30px 0;
          padding: 0 0 0 20px;
          position: relative;
        }
        .heading2:after {
          content: "";
          background-color: ${colors.brand};
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
          border-radius: 2px;
        }
        @media (${breakPoints.sp}) {
          .heading2 {
            font-size: 2.4rem;
          }
        }
        @media (${breakPoints.minPc}) {
          .heading2 {
            font-size: 2.8rem;
          }
        }
      `}</style>
      {node.children && (
        <h2 id={node.children as string} className="heading2">
          {node.children}
        </h2>
      )}
    </>
  );
};

export const Heading3: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading3 {
          color: ${colors.defaultText};
          margin: 30px 0;
          font-weight: 500;
        }
        @media (${breakPoints.sp}) {
          .heading3 {
            font-size: 2rem;
          }
        }
        @media (${breakPoints.minPc}) {
          .heading3 {
            font-size: 2.4rem;
          }
        }
      `}</style>
      <h3 className="heading3">{node.children}</h3>
    </>
  );
};

export const Heading4: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .heading4 {
          color: ${colors.defaultText};
          border-bottom: solid 2px ${colors.gray3};
          margin: 30px 0;
          padding: 0 0 10px;
          font-weight: 500;
        }
        .heading4:first-child {
          margin: 0;
        }
        @media (${breakPoints.sp}) {
          .heading4 {
            font-size: 1.8rem;
          }
        }
        @media (${breakPoints.minPc}) {
          .heading4 {
            font-size: 2rem;
          }
        }
      `}</style>
      <h4 className="heading4">{node.children}</h4>
    </>
  );
};

export const StyledUl: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .styledUl {
          color: ${colors.defaultText};
          margin: 20px 0;
          padding: 0 0 0 30px;
          list-style: disc;
          font-size: 1.6rem;
          font-weight: 400;
        }
      `}</style>
      <ul className="styledUl">{node.children}</ul>
    </>
  );
};

export const StyledOl: React.FC<Node> = (node) => {
  return (
    <>
      <style jsx>{`
        .styledOl {
          color: ${colors.defaultText};
          margin: 20px 0;
          padding: 0 0 0 30px;
          list-style: number;
          font-size: 1.6rem;
          font-weight: 400;
        }
      `}</style>
      <ol className="styledOl">{node.children}</ol>
    </>
  );
};

export const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading4,
    h6: Heading4,
    p: Paragraph,
    a: HyperLink,
    ul: StyledUl,
    ol: StyledOl,
    blockquote: Blockquote,
    img: StyledImg,
  },
}).Compiler;

export const renderHtmlAst = (htmlAst) => renderAst(nl2br(htmlAst));
