import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import dayjs from "dayjs";
import Img from "gatsby-image";
import { LayoutInsight as Layout } from "~/templates/LayoutInsight";
import { breakPoints } from "~/utils/variables";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { InsightBreadcrumbs } from "~/molecules/InsightBreadcrumbs";
import { renderHtmlAst } from "~/molecules/InsightBaseBody";

type Props = {
  path: string;
  data: {
    site: GatsbyTypes.Site;
    article: GatsbyTypes.ContentfulInsightArticle;
    recommendArticles: GatsbyTypes.ContentfulInsightArticleConnection;
  };
};

const InsightArticle: React.FC<Props> = ({ path, data }) => {
  const [isTocOpened, setTocOpened] = useState<Boolean>(true);
  const { site, article } = data;
  const sharedUrl = `${site?.siteMetadata?.siteUrl}${encodeURI(path)}`;
  const h2ForToc = article.body?.childMarkdownRemark?.htmlAst?.children?.filter((node) => {
    return node.tagName === "h2";
  });
  const title = `${article.title} | Refcome(リフカム)`;

  return (
    <>
      <style jsx>{`
        .articleHeader {
          display: flex;
          flex-direction: column;
          max-width: 700px;
          margin: 0 auto;
          padding: 0 20px;
        }
        .articleAttribute {
          display: flex;
          align-items: center;
          margin: 20px 0 0;
        }
        .label {
          border-radius: 30px;
          font-weight: bold;
          font-size: 12px;
          line-height: 1;
          padding: 8px 12px;
        }
        .label.referral {
          background: #daf7f3;
          color: #76c8c8;
        }
        .label.interview {
          background: #d7e3ff;
          color: #779cf7;
        }
        .publishedAt {
          color: ${colors.lightText};
          font-size: 13px;
          margin-left: 15px;
        }
        .title {
          color: ${colors.defaultText};
          font-weight: 500;
        }
        .articleCover {
          margin: 30px auto 0;
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        .articleIntroduction {
          margin: 30px auto 0;
        }
        .articleBody {
          display: flex;
          flex-direction: column;
          max-width: 700px;
          margin: 0 auto;
          padding: 0 20px;
        }
        .tocWrapper {
          background-color: ${colors.gray};
          padding: 20px;
          color: ${colors.lightText};
          width: 100%;
        }
        .tocTitle {
          display: flex;
          align-items: center;
        }
        .tocButton {
          border: none;
          cursor: pointer;
          appearance: none;
          background: transparent;
          font-size: inherit;
          font-weight: inherit;
          font-family: inherit;
          padding: 4px 3px;
          margin: 0;
          margin-right: 10px;
          transform: rotate(-90deg);
        }
        .tocButton.isOpened {
          transform: rotate(0deg);
        }
        .tocButtonArrow {
          width: 12px;
          height: 9px;
          display: block;
        }
        .tocList {
          list-style: none;
        }
        .tocItem :global(a) {
          color: ${colors.lightText};
          font-size: 14px;
        }
        .tocItem :global(a:hover) {
          color: ${colors.defaultText};
        }
        .tocItem {
          padding: 10px 0;
          font-size: 14px;
          font-weight: normal;
        }
        .tocItem + .tocItem {
          border-top: 1px solid ${colors.creamGray2};
        }
        .tocItem:first-child {
          padding: 20px 0 10px;
        }
        .tocItem:last-child {
          padding: 10px 0 0;
        }
        .conversion {
          margin: 40px 0 0;
        }
        .conversionButton {
          display: flex;
          justify-content: center;
        }
        .conversionButton :global(a) {
          display: block;
          padding: 16px 40px;
          background: linear-gradient(
            270deg,
            #ea3375 -73%,
            #ea3572 -69.4%,
            #eb4656 -31.72%,
            #ec5145 1.32%,
            #ec3f50 25.96%,
            #ec8317 100%
          );
          border-radius: 50px;
          color: ${colors.white};
          font-weight: 500;
          font-size: 18px;
        }
        .conversionButton :global(a:hover) {
          opacity: 0.8;
        }
        .snsWrapper {
          margin: 40px 0 0;
        }
        .sns {
          display: flex;
          justify-content: flex-end;
        }
        .sns :global(.facebookShareButton) {
          display: block;
          outline: none;
          width: 32px;
          height: 32px;
        }
        .sns :global(.twitterShareButton) {
          display: block;
          outline: none;
          width: 32px;
          height: 32px;
          margin-left: 10px;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            margin: 30px auto 0;
          }
          .title {
            font-size: 32px;
          }
          .tocWrapper {
            margin: 20px auto 0;
          }
        }
        @media (${breakPoints.minPc}) {
          .wrapper {
            margin: 40px auto 0;
          }
          .title {
            font-size: 40px;
          }
          .tocWrapper {
            margin: 40px auto 0;
          }
        }
      `}</style>
      <Layout title={title} ogpImageUrl={article?.cover?.fixed ? `https:${article?.cover?.fixed?.src}` : undefined} description={data.article.description}>
        <InsightBreadcrumbs article={article} />
        <div className="wrapper">
          <article className="article">
            <div className="articleHeader">
              <h1 className="title">{article.title}</h1>
              <div className="articleAttribute">
                {article.category?.map((c) => (
                  <span key={c?.contentful_id} className={`label ${c?.slug}`}>
                    {c?.name}
                  </span>
                ))}
                <span className="publishedAt">{dayjs(article.publishedAt).format("YYYY.MM.DD")}</span>
              </div>
              <div className="articleCover">
                <Img fluid={article.cover?.fluid} />
              </div>
              <div className="articleIntroduction">
                {renderHtmlAst(article.introduction?.childMarkdownRemark?.htmlAst)}
              </div>
              {h2ForToc.length !== 0 && (
                <div className="tocWrapper">
                  <div className="toc">
                    <h4 className="tocTitle">
                      <button
                        type="button"
                        className={`tocButton ${isTocOpened && `isOpened`}`}
                        onClick={() => setTocOpened(!isTocOpened)}
                      >
                        <svg className="tocButtonArrow">
                          <path fill={colors.lightText} d="M 0 2 L 12 2 L 6 9 z"></path>
                        </svg>
                      </button>
                      目次
                    </h4>
                    {isTocOpened && (
                      <ol className="tocList">
                        {h2ForToc.map((node) => {
                          return (
                            <li key={`tocItem_${node.children[0].value}`} className="tocItem">
                              <Link key={node.children[0].value} to={`${path}#${node.children[0].value}`}>
                                {node.children[0].value}
                              </Link>
                            </li>
                          );
                        })}
                      </ol>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="articleBody">
              {renderHtmlAst(article.body?.childMarkdownRemark?.htmlAst)}
              <div className="conversion">
                <span className="conversionButton">
                  <Link to={PATH.INQUIRY_CONTACT.to}>
                    <span className="conversionButtonText">お問い合わせはこちら</span>
                  </Link>
                </span>
              </div>
              <div className="snsWrapper">
                <div className="sns">
                  <FacebookShareButton className="facebookShareButton" url={sharedUrl}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton className="twitterShareButton" url={sharedUrl} title={article.title}>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </div>
              </div>
              <div className="recommendWrapper">
                <div className="recommend"></div>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </>
  );
};

export default InsightArticle;

export const insightArticleQuery = graphql`
  query InsightArticle($contentfulId: String, $categoryIds: [String]) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    article: contentfulInsightArticle(contentful_id: { eq: $contentfulId }) {
      body {
        id
        childMarkdownRemark {
          id
          htmlAst
        }
      }
      introduction {
        id
        childMarkdownRemark {
          id
          htmlAst
        }
      }
      canonicalUrl
      cover {
        fluid(maxWidth: 700, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
        fixed(width: 1200) {
          src
        }
      }
      contentful_id
      id
      title
      description
      publishedAt
      category {
        contentful_id
        id
        slug
        name
      }
    }
    recommendArticles: allContentfulInsightArticle(
      limit: 3
      filter: { contentful_id: { ne: $contentfulId }, category: { elemMatch: { contentful_id: { in: $categoryIds } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          contentful_id
          body {
            childMarkdownRemark {
              id
            }
          }
          title
        }
      }
    }
  }
`;
