import React from "react";
import { Link } from "gatsby";
import { breakPoints } from "~/utils/variables";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";

type Props = {
  article: GatsbyTypes.ContentfulInsightArticle;
};

export const InsightBreadcrumbs: React.FC<Props> = ({ article }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.defaultBg};
          padding: 16px 30px;
        }
        .breadcrumbs {
          display: flex;
          margin: 0 auto;
          max-width: 1000px;
          list-style: none;
          align-items: center;
        }
        .item {
          font-size: 1.2rem;
          display: flex;
          align-items: flex-start;
          position: relative;
          padding: 0 20px 0 0;
        }
        .item :global(a) {
          color: ${colors.lightText};
        }
        .arrow {
          display: block;
          height: 9px;
          width: auto;
          position: absolute;
          top: 5px;
          right: 7px;
          fill: ${colors.lightText};
        }
        .currentItem {
          color: ${colors.defaultText};
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            display: none;
          }
        }
      `}</style>
      <div className="wrapper">
        <ol className="breadcrumbs">
          <li className="item">
            <Link to={`/`}>HOME</Link>
            <svg className="arrow" viewBox="0 0 13 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M.49 18.13l1.77 1.77 9.9-9.9L2.26.1.49 1.87 8.62 10 .49 18.13z" />
            </svg>
          </li>
          <li className="item">
            <Link to={PATH.INSIGHT.to}>insight</Link>
            <svg className="arrow" viewBox="0 0 13 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M.49 18.13l1.77 1.77 9.9-9.9L2.26.1.49 1.87 8.62 10 .49 18.13z" />
            </svg>
          </li>
          <li className="item">
            <span className="currentItem">{article.title}</span>
          </li>
        </ol>
      </div>
    </>
  );
};
